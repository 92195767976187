import React from 'react';
import { FooterSection } from './styles';

const Footer = () => {
  return (
    <>
      <FooterSection>&copy; Klanium. All Rights Reserved.</FooterSection>
    </>
  );
};
export default Footer;
