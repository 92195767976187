import React from 'react';

import { Styles } from '../styles/global';
import RouterConfig from './RouterConfig';

const Router = () => {
  return (
    <React.Suspense fallback={null}>
      <Styles />
      {/*<Header />*/}
      <RouterConfig />
      {/*<Footer />*/}
    </React.Suspense>
  );
};

export default Router;
