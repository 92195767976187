import React from 'react';
import { RevealImageWrapper } from './styles';

interface RevealImageProps {
  image: string;
}

const RevealImage = ({ image }: RevealImageProps) => {
  return (
    <RevealImageWrapper image={image}>
      <svg viewBox="0 0 50 50" width="50" height="50">
        <defs>
          <mask id="mask" x="0" y="0" width="100" height="49">
            <rect x="0.5" y="0.5" width="49" height="49" fill="#fff" />
            <text x="6" textAnchor="middle" y="50" dy="0">
              K
            </text>
            <text x="13" id="ltrV" textAnchor="middle" y="50" dy="0">
              L
            </text>
            <text x="20" textAnchor="middle" y="50" dy="0">
              A
            </text>
            <text x="27" textAnchor="middle" y="50" dy="0">
              N
            </text>
            <text x="32" textAnchor="middle" y="50" dy="0">
              I
            </text>
            <text x="37" textAnchor="middle" y="50" dy="0">
              U
            </text>
            <text x="44" textAnchor="middle" y="50" dy="0">
              M
            </text>
          </mask>
        </defs>
        <rect
          x="0.5"
          y="0.5"
          width="49"
          height="49"
          mask="url(#mask)"
          fillOpacity="1"
          fill="#812fda"
        />
      </svg>
    </RevealImageWrapper>
  );
};

export default RevealImage;
