import styled from 'styled-components';

export const NumberInputButton = styled('button')`
  color: #fff;
  font-size: 3rem;
  padding: 0 1rem;
  backdrop-filter: blur(16px) saturate(100%);
  background-color: rgba(80, 80, 80, 0.1);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);

  &:hover {
    cursor: pointer;
    background-color: rgba(80, 80, 80, 0.2);
  }

  &:disabled {
    color: #666;
    cursor: not-allowed;
  }
`;

export const NumberDisplay = styled('div')`
  font-size: 3rem;
  color: #fff;
  padding: 0 5rem;
`;
