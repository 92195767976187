import { createGlobalStyle } from 'styled-components';

export const Styles = createGlobalStyle`
  html,
  body { 
    background-color: #090315 ;
    padding: 0;
    margin: 0;
    cursor: default;
    font-size: 62.5%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    @media only screen and (max-width: 1610px) {
      font-size: 56%;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 40%;
    }
    @media only screen and (max-width: 992px) {
      font-size: 40%;
    }

    @media only screen and (max-width: 768px) {
      font-size: 50%;
    }
  }


  
  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  p {
    margin: 0px;
  }
`;
