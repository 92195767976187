import React, { useState, useEffect } from 'react';
import {
  NavArea,
  NavContainer,
  NavLeft,
  NavRight,
  NavMenu,
  NavIcons,
  NavItem,
  NavItemDisabled,
  MenuTitle,
  Label,
  Outline,
  Burger,
  MobileNavWrapper,
  NavCloseOutlined,
  DesktopNavWrapper,
  ChangeLangButton,
  NavItemContainer
} from './styles';
import { Drawer } from 'antd';

const Header = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const toggleDrawer = () => setVisibleDrawer(!visibleDrawer);

  const [currentLang, setCurrentLang] = useState('ko');

  const NavItems = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      setVisibleDrawer(false);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    };
    return (
      <NavItemContainer>
        <NavItem>Mint</NavItem>
        <NavItemDisabled>Fusion</NavItemDisabled>
        <NavItemDisabled>Marketplace</NavItemDisabled>
        <NavItem>About</NavItem>
      </NavItemContainer>
    );
  };

  const NavIconItems = () => {
    return (
      <ul>
        <li>
          <figure>
            <a
              href="https://twitter.com/lolclubnft"
              target="_blank"
              rel="noreferrer"
            >
              <img src="header/twitter.png" alt="twitter" />
            </a>
          </figure>
        </li>
        <li>
          <figure>
            <a
              href="https://discord.gg/lolclub"
              target="_blank"
              rel="noreferrer"
            >
              <img src="header/discord.png" alt="discord" />
            </a>
          </figure>
        </li>
        <li>
          <figure>
            <a
              href="https://open.kakao.com/o/gV5AJt4d"
              target="_blank"
              rel="noreferrer"
            >
              <img src="header/telegram.png" alt="telegram" />
            </a>
          </figure>
        </li>
      </ul>
    );
  };

  return (
    <NavArea>
      <NavContainer>
        <img src="logo_klanium.png" alt="navLogo" />

        {/*<NavLeft>*/}
        {/*  <img src="logo_klanium.png" alt="navLogo" />*/}
        {/*</NavLeft>*/}
        {/*<NavRight>*/}
        {/*  <DesktopNavWrapper>*/}
        {/*    <NavMenu>*/}
        {/*      <NavItems />*/}
        {/*    </NavMenu>*/}
        {/*    <NavIcons>*/}
        {/*      <NavIconItems />*/}
        {/*    </NavIcons>*/}
        {/*  </DesktopNavWrapper>*/}
        {/*  <Burger onClick={toggleDrawer}>*/}
        {/*    <Outline />*/}
        {/*  </Burger>*/}
        {/*  <Drawer*/}
        {/*    closable={false}*/}
        {/*    visible={visibleDrawer}*/}
        {/*    onClose={toggleDrawer}*/}
        {/*    drawerStyle={{ backgroundColor: '#111420' }}*/}
        {/*  >*/}
        {/*    <MobileNavWrapper>*/}
        {/*      <Label onClick={toggleDrawer}>*/}
        {/*        <NavCloseOutlined />*/}
        {/*      </Label>*/}
        {/*      <NavItems />*/}
        {/*      <NavIcons>*/}
        {/*        <NavIconItems />*/}
        {/*      </NavIcons>*/}
        {/*    </MobileNavWrapper>*/}
        {/*  </Drawer>*/}
        {/*</NavRight>*/}
      </NavContainer>
    </NavArea>
  );
};

export default Header;
