import { getMintPrice, getProjectInfo, ProjectInfo } from './ProjectUtil';
import { ethers } from 'ethers';
import axios from 'axios';
import { HOST, PROJECT } from '../config';

const WALLET_KIND = {
  METAMASK: 'metamask',
  KAIKAS: 'kaikas'
} as const;
export type WALLET_KIND = typeof WALLET_KIND[keyof typeof WALLET_KIND];

type Signature = {
  v: string;
  r: string;
  s: string;
};

type WalletInfo = {
  walletKind: WALLET_KIND;
  walletAddress: string;
};

let connectedWalletKind: WALLET_KIND;
let connectedWalletAddress: string;

export function getConnectedWallet(): WalletInfo {
  return {
    walletKind: connectedWalletKind,
    walletAddress: connectedWalletAddress
  };
}

export function getChainId(walletKind: WALLET_KIND) {
  if (walletKind === WALLET_KIND.METAMASK) {
    return (window as any).ethereum.chainId;
  } else if (walletKind === WALLET_KIND.KAIKAS) {
    return (
      '0x' + (window as any).caver.currentProvider.networkVersion.toString(16)
    );
  }
}

export async function connectMetamask() {
  try {
    if (getChainId('metamask') !== '0x2019') {
      // Check provider is Klaytn
      try {
        await (window as any).ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x2019',
              chainName: 'Klaytn Cypress',
              nativeCurrency: {
                name: 'KLAY',
                symbol: 'KLAY',
                decimals: 18
              },
              rpcUrls: ['https://public-node-api.klaytnapi.com/v1/cypress'],
              blockExplorerUrls: ['https://scope.klaytn.com']
            }
          ]
        });
        await (window as any).ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: '0x2019'
            }
          ]
        });
      } catch (e) {
        console.log('Rejected changing network');
      }
    }

    connectedWalletAddress = (await (window as any).ethereum.enable())[0];
    connectedWalletKind = WALLET_KIND.METAMASK;
  } catch (e) {
    console.error('Rejected connecting Metamaks');
  }
  return connectedWalletAddress;
}

export async function connectKaikas() {
  try {
    connectedWalletAddress = (await (window as any).klaytn.enable())[0];
    connectedWalletKind = WALLET_KIND.KAIKAS;
  } catch (e) {
    console.error('Rejected connecting Kaikas');
  }
  return connectedWalletAddress;
}

export function connectedWallet() {
  return {
    walletKind: connectedWalletKind,
    walletAddress: connectedWalletAddress
  };
}

function getProvider() {
  return connectedWalletKind === WALLET_KIND.KAIKAS
    ? new ethers.providers.Web3Provider((window as any).klaytn)
    : new ethers.providers.Web3Provider((window as any).ethereum);
}

function getContract(address: string, abi: any[]) {
  if (connectedWalletKind === WALLET_KIND.KAIKAS) {
    // return new ethers.Contract(address, abi, getProvider());
    return new (window as any).caver.klay.Contract(abi, address);
  } else if (connectedWalletKind === WALLET_KIND.METAMASK) {
    return new ethers.Contract(address, abi, getProvider().getSigner());
  }
}

export async function mint(amount = 1) {
  const projectInfo = getProjectInfo();
  if (!connectedWalletKind || !connectedWalletAddress) return;

  const contract = getContract(projectInfo.contractAddress, abi);

  const signer = getProvider().getSigner(connectedWalletAddress);

  if (!contract) return;

  const mintSignature = (
    await axios.get(
      HOST +
        `/api/project/ticket_signature/${PROJECT}?walletAddress=${connectedWalletAddress}`
    )
  ).data?.signature;

  let receipt;
  if (projectInfo.isPreSale1 || projectInfo.isPreSale2) {
    if (mintSignature) {
      receipt = await mintWithSignature(contract, amount, {
        v: mintSignature._v,
        r: mintSignature._r,
        s: mintSignature._s
      });
    } else {
      receipt = await mintPreSale(contract, amount);
    }
  } else {
    // Public Sale
    receipt = await mintPreSale(contract, amount);
  }

  try {
    (window as any).gtag('event', 'Mint NFT', { amount });
  } catch (e) {
    console.error(e);
  }
}

export async function mintWithSignature(
  contract: any,
  amount: number,
  signature: Signature
) {
  const projectInfo = getProjectInfo();

  const bnPrice = ethers.BigNumber.from(projectInfo.preSalePrice);
  const totalValue = bnPrice.mul(ethers.BigNumber.from(amount)).toString();
  if (connectedWalletKind === WALLET_KIND.KAIKAS) {
    return await contract.methods
      .mintWithSign(signature.v, signature.r, signature.s, amount)
      .send({
        from: connectedWalletAddress,
        gas: 1000000,
        value: totalValue
      });
  } else if (connectedWalletKind === WALLET_KIND.METAMASK) {
    return await contract.mintWithSign(
      signature.v,
      signature.r,
      signature.s,
      amount,
      {
        value: totalValue,
        gasLimit: 1000000
      }
    );
  }
}

export async function mintPreSale(contract: any, amount: number) {
  const projectInfo = getProjectInfo();

  const price =
    projectInfo.isPreSale1 || projectInfo.isPreSale2
      ? projectInfo.preSalePrice
      : projectInfo.publicSalePrice;
  const bnPrice = ethers.BigNumber.from(price);
  const totalValue = bnPrice.mul(ethers.BigNumber.from(amount)).toString();

  if (connectedWalletKind === WALLET_KIND.KAIKAS) {
    return await contract.methods.mintWithWhitelist(amount).send({
      from: connectedWalletAddress,
      gas: 1000000,
      value: totalValue
    });
  } else if (connectedWalletKind === WALLET_KIND.METAMASK) {
    return await contract.mintWithWhitelist(amount, {
      value: totalValue,
      gasLimit: 1000000
    });
  }
}

export async function isMintable(
  walletAddress: string,
  walletKind: WALLET_KIND
) {
  let isMintable = true;

  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 1000);
    isMintable = (
      (await (
        await fetch(
          `${HOST}/api/project/isMintable/${PROJECT}?walletAddress=${walletAddress}`,
          {
            signal: controller.signal
          }
        )
      ).json()) as any
    ).mintable;
    clearTimeout(timeoutId);

    isMintable =
      isMintable || (await isInNftWhitelist(walletAddress, walletKind));
  } catch (e) {
    isMintable = await isInNftWhitelist(walletAddress, walletKind);
  }

  return isMintable;
}

async function isInNftWhitelist(
  walletAddress: string,
  walletKind: WALLET_KIND
) {
  const { contractAddress, isPreSale1, isPreSale2 } = getProjectInfo();
  const contract = getContract(contractAddress, abi);

  let isInWhiteList = true;

  if (isPreSale1) {
    if (walletKind === WALLET_KIND.KAIKAS) {
      isInWhiteList = await contract.methods
        .isPreSale1Whitelist(walletAddress)
        .call();
    } else if (walletKind === WALLET_KIND.METAMASK) {
      isInWhiteList = await contract.isPreSale1Whitelist(walletAddress);
    }
  } else if (isPreSale2) {
    if (walletKind === WALLET_KIND.KAIKAS) {
      isInWhiteList = await contract.methods
        .isPreSale2Whitelist(walletAddress)
        .call();
    } else if (walletKind === WALLET_KIND.METAMASK) {
      isInWhiteList = await contract.isPreSale2Whitelist(walletAddress);
    }
  } else {
    isInWhiteList = true;
  }
  return isInWhiteList;
}

const abi = [
  {
    constant: true,
    inputs: [
      {
        name: 'interfaceId',
        type: 'bytes4'
      }
    ],
    name: 'supportsInterface',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'getApproved',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'approve',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'transferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      },
      {
        name: 'index',
        type: 'uint256'
      }
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'unpause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'burn',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'isPauser',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'index',
        type: 'uint256'
      }
    ],
    name: 'tokenByIndex',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'paused',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'ownerOf',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'renouncePauser',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      }
    ],
    name: 'balanceOf',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'addPauser',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'pause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [
      {
        name: '',
        type: 'address'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'isOwner',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'addMinter',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'renounceMinter',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'approved',
        type: 'bool'
      }
    ],
    name: 'setApprovalForAll',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'account',
        type: 'address'
      }
    ],
    name: 'isMinter',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'from',
        type: 'address'
      },
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      },
      {
        name: '_data',
        type: 'bytes'
      }
    ],
    name: 'safeTransferFrom',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'tokenURI',
    outputs: [
      {
        name: '',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'MAX_TOKEN_SUPPLY',
    outputs: [
      {
        name: '',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'owner',
        type: 'address'
      },
      {
        name: 'operator',
        type: 'address'
      }
    ],
    name: 'isApprovedForAll',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: 'to',
        type: 'address'
      },
      {
        indexed: false,
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'Withdraw',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'MinterAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'MinterRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'Paused',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'Unpaused',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'PauserAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'account',
        type: 'address'
      }
    ],
    name: 'PauserRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address'
      },
      {
        indexed: true,
        name: 'to',
        type: 'address'
      },
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'Transfer',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        name: 'approved',
        type: 'address'
      },
      {
        indexed: true,
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'Approval',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        name: 'operator',
        type: 'address'
      },
      {
        indexed: false,
        name: 'approved',
        type: 'bool'
      }
    ],
    name: 'ApprovalForAll',
    type: 'event'
  },
  {
    constant: true,
    inputs: [],
    name: 'info',
    outputs: [
      {
        name: 'preSale1Supply',
        type: 'uint256'
      },
      {
        name: 'preSale1StartBlock',
        type: 'uint256'
      },
      {
        name: 'preSale2Supply',
        type: 'uint256'
      },
      {
        name: 'preSale2StartBlock',
        type: 'uint256'
      },
      {
        name: 'preSalePrice',
        type: 'uint256'
      },
      {
        name: 'publicSaleSupply',
        type: 'uint256'
      },
      {
        name: 'publicSaleStartBlock',
        type: 'uint256'
      },
      {
        name: 'publicSalePrice',
        type: 'uint256'
      },
      {
        name: 'mintBlockInterval',
        type: 'uint256'
      },
      {
        name: 'tokenIndex',
        type: 'uint256'
      },
      {
        name: 'maxMintAmount',
        type: 'uint256'
      },
      {
        name: 'baseURI',
        type: 'string'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'nftContracts',
        type: 'address[]'
      },
      {
        name: 'minBalances',
        type: 'uint256[]'
      }
    ],
    name: 'setPreSale1Whitelist',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'user',
        type: 'address'
      }
    ],
    name: 'isPreSale1Whitelist',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'isPreSale1',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'nftContracts',
        type: 'address[]'
      },
      {
        name: 'minBalances',
        type: 'uint256[]'
      }
    ],
    name: 'setPreSale2Whitelist',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'user',
        type: 'address'
      }
    ],
    name: 'isPreSale2Whitelist',
    outputs: [
      {
        name: 'result',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'isPreSale2',
    outputs: [
      {
        name: 'result',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'user',
        type: 'address'
      }
    ],
    name: 'getMintInfo',
    outputs: [
      {
        name: '',
        type: 'uint256'
      },
      {
        name: '',
        type: 'uint256'
      },
      {
        name: '',
        type: 'uint256'
      },
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [
      {
        name: 'user',
        type: 'address'
      }
    ],
    name: 'isWhitelist',
    outputs: [
      {
        name: 'result',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getMintPrice',
    outputs: [
      {
        name: 'preSalePrice',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: true,
    inputs: [],
    name: 'getTokenSupply',
    outputs: [
      {
        name: 'supply',
        type: 'uint256'
      }
    ],
    payable: false,
    stateMutability: 'view',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'supply',
        type: 'uint256'
      }
    ],
    name: 'setPreSale1Supply',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'blockNumber',
        type: 'uint256'
      }
    ],
    name: 'setPreSale1StartBlock',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'supply',
        type: 'uint256'
      }
    ],
    name: 'setPreSale2Supply',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'blockNumber',
        type: 'uint256'
      }
    ],
    name: 'setPreSale2StartBlock',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'preSalePrice',
        type: 'uint256'
      }
    ],
    name: 'setPreSalePrice',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'supply',
        type: 'uint256'
      }
    ],
    name: 'setPublicSaleSupply',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'blockNumber',
        type: 'uint256'
      }
    ],
    name: 'setPublicSaleStartBlock',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'publicSalePrice',
        type: 'uint256'
      }
    ],
    name: 'setPublicSalePrice',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'mintBlockInterval',
        type: 'uint256'
      }
    ],
    name: 'setMintBlockInterval',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'maxMintAmount',
        type: 'uint256'
      }
    ],
    name: 'setMaxMintAmount',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'klaniumSigner',
        type: 'address'
      }
    ],
    name: 'setKlaniumSigner',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'uri',
        type: 'string'
      }
    ],
    name: 'setBaseURI',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'to',
        type: 'address'
      },
      {
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'mint',
    outputs: [
      {
        name: '',
        type: 'bool'
      }
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'mintWithWhitelist',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [
      {
        name: 'v',
        type: 'uint8'
      },
      {
        name: 'r',
        type: 'bytes32'
      },
      {
        name: 's',
        type: 'bytes32'
      },
      {
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'mintWithSign',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function'
  },
  {
    constant: false,
    inputs: [],
    name: 'withdrawBalance',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
];
