import { ProgressBarInfo } from './ProgressBarInfo';

export const getTotalQuantity = (p: ProgressBarInfo) => p.phase3.supplyQuantity;

export const getLeftQuantity = (p: ProgressBarInfo) =>
  getTotalQuantity(p) - p.currentQuantity;

const getPercent = (
  current: number,
  supply: number,
  minimumPercent: number
) => {
  if (supply === 0) return '0%';
  let percent = Math.min((current / supply) * 100, 100);

  if (percent <= 0) {
    percent = 0;
  } else if (percent < minimumPercent) {
    percent = minimumPercent;
  }

  return percent + '%';
};

export const getPhase1Percent = (p: ProgressBarInfo, minimumPercent = 5) =>
  getPercent(p.currentQuantity, p.phase1.supplyQuantity, minimumPercent);

export const getPhase2Percent = (p: ProgressBarInfo, minimumPercent = 5) => {
  return getPercent(
    p.currentQuantity - p.phase1.supplyQuantity,
    p.phase2.supplyQuantity - p.phase1.supplyQuantity,
    minimumPercent
  );
};
export const getPhase3Percent = (p: ProgressBarInfo, minimumPercent = 5) =>
  getPercent(
    p.currentQuantity - p.phase2.supplyQuantity,
    p.phase3.supplyQuantity - p.phase2.supplyQuantity,
    minimumPercent
  );
