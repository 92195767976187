import React from 'react';
import { useRoutes } from 'react-router-dom';
import Home from '../pages/Home';

const RouterConfig = (): JSX.Element => {
  const mainRoutes = {
    path: '/',
    element: <Home />,
    children: [
      // {path: '*', element: <Navigate to='/404' />},
      { path: '*', element: <Home /> },
      { path: 'home', element: <Home /> }
    ]
  };
  // const subRoutes = [
  //   { path: 'mint', element: <MintPage /> },
  //   { path: '/fusion', element: <Fusion /> }
  // ];
  //
  return <>{useRoutes([mainRoutes])}</>;
};

export default RouterConfig;
