/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
import axios from 'axios';
import { HOST, PROJECT } from '../config';
import { sleep } from './functools';
import { ethers } from 'ethers';
import { WALLET_KIND } from './WalletUtil';

export type ProjectInfo = {
  contractAddress: string;
  maxMintAmount: number;
  mintBlockInterval: number;
  preSale1StartBlock: number;
  preSale2StartBlock: number;
  publicSaleStartBlock: number;
  preSale1Supply: number;
  preSale2Supply: number;
  preSalePrice: string;
  publicSalePrice: string;
  publicSaleSupply: number;
  tokenIndex: number;
  isPreSale1: boolean;
  isPreSale2: boolean;
};

let projectInfo = {
  contractAddress: '',
  maxMintAmount: 0,
  mintBlockInterval: 0,
  preSale1StartBlock: 0,
  preSale2StartBlock: 0,
  publicSaleStartBlock: 0,
  preSale1Supply: 0,
  preSale2Supply: 0,
  preSalePrice: '0',
  publicSalePrice: '0',
  publicSaleSupply: 0,
  tokenIndex: 0,
  isPreSale1: true,
  isPreSale2: false
};

const UPDATE_INTERVAL = 5000; // 5sec
const RANDOM_RANGE = 10000; // 10sec

export async function updateProjectInfo() {
  const mintInfo = (await axios.get(HOST + `/api/project/mintInfo/${PROJECT}`))
    .data as ProjectInfo;

  projectInfo = mintInfo;
}

async function projectInfoJob() {
  while (true) {
    try {
      await updateProjectInfo();
    } catch (e) {
      console.error(e);
    } finally {
      const timeDiff = Math.floor(Math.random() * RANDOM_RANGE);
      await sleep(UPDATE_INTERVAL + timeDiff); // sleep 5 to 15 sec
    }
  }
}

projectInfoJob();

export function getProjectInfo(): ProjectInfo {
  return projectInfo;
}

export function getMintingBlock(projectInfo: ProjectInfo): number {
  if (projectInfo.isPreSale1) {
    return projectInfo.preSale1StartBlock;
  } else if (projectInfo.isPreSale2) {
    return projectInfo.preSale2StartBlock;
  } else {
    return projectInfo.publicSaleStartBlock;
  }
}

export function getMintPrice(projectInfo: ProjectInfo): string {
  if (projectInfo.isPreSale1) {
    return ethers.utils.formatEther(projectInfo.preSalePrice);
  } else if (projectInfo.isPreSale2) {
    return ethers.utils.formatEther(projectInfo.preSalePrice);
  } else {
    return ethers.utils.formatEther(projectInfo.publicSalePrice);
  }
}
