import styled from 'styled-components';
import { NftInfoItemWrapper } from './NftInfoItem/styles';

export const NftInfoWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-basis: 20rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  ${NftInfoItemWrapper}:first-child {
    padding-left: 0;
    @media only screen and (max-width: 768px) {
      padding-left: 1rem;
    }
  }

  ${NftInfoItemWrapper} + ${NftInfoItemWrapper} {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #ffffff50;
      height: 50%;
      left: 0;
      top: 25%;
    }
    @media only screen and (max-width: 768px) {
      &:before {
        content: none;
      }
    }
  }
`;
