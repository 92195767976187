import { DescriptionInfo } from './components/Description';

export const DescriptionContents: DescriptionInfo[] = [
  {
    image: 'logo_lol_club.png',
    title: 'About the LOL-CLUB',
    content: `The journey with Legend Kong of LOL-CLUB

LOL-CLUB project is, the premium membership NFT, game utility NFT for those who are serious about LOL game.

The biggest goal of LOL-CLUB is to communicate with professional gamers, influencers,and lol users around the world who can join hands through LOL-CLUB and expand the metaverse world together.`
  },
  {
    title: 'Schedule',
    content: `The total quantity of LOL-CLUB is 10,000.
(300 for Partnership, Marketing, and Operation / 400 for LOL-CLUB team)

March 21, 9:00pm KST (1st pre-sale) - 1,500 NFT (180 Klay)
March 21, 10:00pm KST (2nd pre-sale) - 2,500 NFT (180 Klay)
March 21, 11:00pm KST (public sale) - 5,300 NFT (200 Klay)
      `
  }
];
