import styled from 'styled-components';
import RoundedButton from '../../components/RoundedButton';

export const MintingContainer = styled('div')`
  background-color: #170c32;
  border-radius: 3rem;
  padding: 3rem;
  margin: 8rem 5rem 15rem 5rem;
`;

export const NtfWrapper = styled('div')`
  width: 100%;
`;

export const InfoWrapper = styled('div')`
  width: 100%;
`;

export const NftNameText = styled('span')`
  font-size: 3rem;
  display: inline-block;
  color: #ffffff;
  margin-right: 2rem;
`;

export const MintingStatus = styled('span')`
  font-size: 1.5rem;
  color: #45b26b;

  &:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #45b26b;
    margin-right: 0.6rem;
  }
`;

export const WalletButtonWrapper = styled('div')`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  @media only screen and (max-width: 768px) {
    margin: 1rem 0;
    justify-content: start;
    flex-direction: column;
  }
  color: #fff;
`;

export const MarginWrapper = styled('div')<any>`
  width: 100%;
  margin-bottom: 5rem;
  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

export const TitleAndButtonWrapper = styled('div')`
  display: flex;
  align-items: end;
  @media only screen and (max-width: 768px) {
    align-items: start;
    flex-direction: column;
    flex-wrap: wrap;
  }
  width: 100%;
`;

export const MintButtonWrapper = styled('div')`
  display: flex;
  margin-bottom: 5rem;
  align-items: center;
  flex-flow: wrap;
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 1rem;
  }
  justify-content: center;
`;

export const NumberInputWrapper = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 3rem;
  }
`;

export const MintWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
