import styled from 'styled-components';

export const DescriptionWrapper = styled('div')`
  width: 100%;
  padding: 2rem;
  background-color: #0d071e;
  border-radius: 2rem;
`;

export const DescriptionTitle = styled('h1')`
  position: relative;
  text-align: center;
  &:before {
    content: '';
    display: block;
    border-top: solid 2px #333;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 0;
    @media only screen and (max-width: 768px) {
      content: none;
    }
  }
  span {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 3rem;
    padding: 0 5rem;
    background: #0d071e;
    font-weight: bold;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
    @media only screen and (max-width: 425px) {
      font-size: 2rem;
    }
  }
`;
export const DescriptionContentWrapper = styled('div')`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

export const DescriptionContent = styled('pre')`
  color: #aaa;
  font-size: 1.5rem;
  word-wrap: break-word; /* IE 5.5-7 */
  white-space: pre-wrap;
`;

export const DescriptionImage = styled('div')<any>`
  width: 20rem;
  height: 20rem;
  background: url(${p => p.image}) #000;
  background-size: 10rem;
  border-radius: 20rem;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 3rem;
  @media only screen and (max-width: 768px) {
    margin: 2rem auto;
  }
`;
