import React from 'react';

import {
  ProgressAnimation,
  RemainingProgress,
  RemainingProgressStep1,
  RemainingProgressStep2,
  RemainingProgressStep3,
  RemainingProgressText,
  RemainingStatus,
  RemainingStatusName,
  RemainingStatusValue,
  RemainingStatusWrapper
} from './styles';
import AnimateFormattedNumber from '../../../../components/AnimateFormattedNumber';
import { ProgressBarInfo } from './ProgressBarInfo';
import {
  getLeftQuantity,
  getPhase1Percent,
  getPhase2Percent,
  getPhase3Percent,
  getTotalQuantity
} from './utils';

const numberFormat = (num: number) =>
  new Intl.NumberFormat('en-us').format(num);

const ProgressBar = (progressBarInfo: ProgressBarInfo) => {
  return (
    <>
      <RemainingStatusWrapper>
        <RemainingStatus>
          <RemainingStatusName>Total Supply</RemainingStatusName>
          <RemainingStatusValue>
            <AnimateFormattedNumber
              targetNumber={getTotalQuantity(progressBarInfo)}
            />
          </RemainingStatusValue>
        </RemainingStatus>
        <RemainingStatus>
          <RemainingStatusName>Mintend</RemainingStatusName>
          <RemainingStatusValue>
            <AnimateFormattedNumber
              targetNumber={progressBarInfo.currentQuantity}
            />
          </RemainingStatusValue>
        </RemainingStatus>
        <RemainingStatus>
          <RemainingStatusName color={'#ff9b33'}>Left</RemainingStatusName>
          <RemainingStatusValue>
            <AnimateFormattedNumber
              targetNumber={getLeftQuantity(progressBarInfo)}
            />
          </RemainingStatusValue>
        </RemainingStatus>
      </RemainingStatusWrapper>

      <RemainingProgress>
        <RemainingProgressText>
          {progressBarInfo.phase1.name}
        </RemainingProgressText>
        <RemainingProgressText sideMargin>
          {progressBarInfo.phase2.name}
        </RemainingProgressText>
        <RemainingProgressText>
          {progressBarInfo.phase3.name}
        </RemainingProgressText>
        <RemainingProgressStep1>
          <ProgressAnimation
            index={1}
            stopPosition={getPhase1Percent(progressBarInfo)}
          />
        </RemainingProgressStep1>
        <RemainingProgressStep2>
          <ProgressAnimation
            index={2}
            stopPosition={getPhase2Percent(progressBarInfo)}
          />
        </RemainingProgressStep2>
        <RemainingProgressStep3
          totalQuantity={numberFormat(getTotalQuantity(progressBarInfo))}
        >
          <ProgressAnimation
            index={3}
            stopPosition={getPhase3Percent(progressBarInfo)}
          />
        </RemainingProgressStep3>
        <RemainingProgressText rightAlign>
          {numberFormat(progressBarInfo.phase1.supplyQuantity)}
        </RemainingProgressText>
        <RemainingProgressText sideMargin rightAlign>
          {numberFormat(progressBarInfo.phase2.supplyQuantity)}
        </RemainingProgressText>
        <RemainingProgressText rightAlign>
          {numberFormat(progressBarInfo.phase3.supplyQuantity)}
        </RemainingProgressText>
      </RemainingProgress>
    </>
  );
};

export default ProgressBar;
