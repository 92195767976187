import styled, { css } from 'styled-components';

export const ButtonWrapper = styled('div')<any>`
  display: inline-block;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
  position: relative;
  background-color: #812fda;
  color: #fff;
  cursor: pointer;
  font-size: 2rem;
  line-height: 6rem;
  border-radius: 3rem;
  padding: 0 3rem;
  margin: ${p => (p.margin ? p : '0')};
  ${p => {
    if (p.image) {
      return css`
        &:before {
          position: absolute;
          left: 0.3rem;
          top: 0.5rem;
          background: url(${p.image}) #000;
          display: inline-block;
          background-size: 3rem;
          background-position: center;
          background-repeat: no-repeat;
          width: 5rem;
          height: 5rem;
          border-radius: 3rem;

          content: '';
        }
        padding-left: 7rem;
      `;
    }
  }};
`;

export const DisabledButton = styled(ButtonWrapper)`
  background-color: #200f42;
  color: #7b3ec2;
  cursor: not-allowed;
`;
