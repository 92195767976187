import styled, { css } from 'styled-components';

const progressBarSize = '35px';

export const RemainingStatus = styled('div')`
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding: 0 5rem;
  width: 30%;
  @media only screen and (max-width: 768px) {
    text-align: start;
    padding: 0;
  }
`;

export const RemainingStatusName = styled('p')<any>`
  font-size: 2rem;
  color: ${p => (p.color ? p.color : '#8738db')};
`;
export const RemainingStatusValue = styled('p')`
  font-size: 1.5rem;
  color: #fff;
`;
export const RemainingStatusWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-basis: 20rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  ${RemainingStatus}:first-child {
    padding-left: 0;
    &:before {
      padding-left: inherit;
    }
  }

  ${RemainingStatus} + ${RemainingStatus} {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #ffffff50;
      height: 50%;
      left: 0;
      top: 25%;
    }
    @media only screen and (max-width: 768px) {
      &:before {
        content: none;
      }
    }
  }
`;
export const RemainingProgressText = styled('li')<any>`
  display: inline-block;
  font-size: 1.5rem;
  width: 30%;
  color: #fff;
  margin: 5px 0;
  ${p => {
    if (p.rightAlign) {
      return css`
        text-align: right;
      `;
    }
  }}

  ${p => {
    if (p.sideMargin) {
      return css`
        margin: 0 5px;
      `;
    }
  }}
`;
export const RemainingProgressStep1 = styled('li')`
  border-top-left-radius: ${progressBarSize};
  border-bottom-left-radius: ${progressBarSize};
  &:before {
    content: '0';
    color: #fff;
    position: absolute;
    font-size: 1.5rem;
    line-height: ${progressBarSize};
    text-indent: -20px;
    //transform: translate(-20px, 5px);
  }
`;

interface ProgressAnimationProps {
  index: number;
  stopPosition: string;
}

export const ProgressAnimation = styled('div')<ProgressAnimationProps>`
  width: 100%;
  ${p => {
    switch (p.index) {
      case 1:
        return css`
          border-top-left-radius: ${progressBarSize};
          border-bottom-left-radius: ${progressBarSize};
        `;
      case 3:
        return css`
          border-top-right-radius: ${progressBarSize};
          border-bottom-right-radius: ${progressBarSize};
        `;
      default:
        return;
    }
  }}

  height: ${progressBarSize};
  transition: 0.4s linear;
  transition-property: width, background-color;
  ${p => {
    if (p.index) {
      const animationName = 'progress' + p.index;
      return css`
        animation: ${animationName} 2s forwards;
        animation-delay: ${(p.index - 1) * 2}s;

        @keyframes ${animationName} {
          0% {
            width: 0;
            background: #170c32;
          }

          100% {
            width: ${p.stopPosition ? p.stopPosition : '100%'};
            background: #ff9b33;
            box-shadow: 0 0 30px #ff9b33;
          }
        }
      `;
    }
  }}
`;

export const RemainingProgressStep2 = styled('li')`
  margin: 0 5px;
`;

interface TotalQuantity {
  totalQuantity: string;
}

export const RemainingProgressStep3 = styled('li')<TotalQuantity>`
  border-top-right-radius: ${progressBarSize};
  border-bottom-right-radius: ${progressBarSize};
  &:after {
    content: ${p => `'${p.totalQuantity}'`};
    color: #fff;
    position: absolute;
    font-size: 1.5rem;
    line-height: ${progressBarSize};
    left: 90%;
    top: 32%;
    text-indent: 2.1rem;
  }
`;
export const RemainingProgress = styled('ul')`
  padding: 0;
  display: block;
  position: relative;
  ${RemainingProgressStep1}, ${RemainingProgressStep2},
    ${RemainingProgressStep3} {
    width: 30%;
    height: ${progressBarSize};
    display: inline-block;
    background-color: #414141;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  // ${RemainingProgressText} {
  //   display: inline-block;
  //   font-size: 1.5rem;
  //   width: 30%;
  //   color: #fff;
  //   margin: 5px 0;
  // }
`;
