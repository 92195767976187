import styled from 'styled-components';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

export const ChangeLangButton = styled.button`
  width: 60px;
  height: 22px;
  cursor: pointer;
  background: transparent;
  border: none;

  span {
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  @media only screen and (max-width: 890px) {
    width: 40px;
    height: 40px;
  }
`;

export const NavArea = styled('div')`
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #000;
  background-color: #0e0823;
`;

export const NavContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 91.1%;
  img {
    width: 30px;
    margin: 0 auto;
  }
`;

export const NavLeft = styled('div')`
  width: 10%;
  img {
    position: absolute;
    top: 20px;
    width: 30px;
  }
`;

export const NavRight = styled('div')`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavItem = styled('li')`
  cursor: pointer;
  margin: 0 3%;
  transition: 0.3s linear;
  &:hover {
    color: #ccc;
  }
`;

export const NavItemDisabled = styled(NavItem)`
  cursor: not-allowed;
  color: #a4a4a4;
  &:hover {
    color: #a4a4a4;
  }
`;

export const DesktopNavWrapper = styled(NavRight)`
  width: 100%;
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const NavItemContainer = styled('ul')`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 890px) {
    display: block;
  }
`;

export const NavMenu = styled('nav')`
  width: 100%;

  ul {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    padding-inline-start: 0px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    padding-right: 5%;
    text-align: right;
    list-style-position: inside;

    ${NavItem} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }

    ${NavItemDisabled} {
      display: inline-flex;
      button {
        background: none;
        border: 0;
        display: inherit;
        justify-content: inherit;
        margin: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-style: inherit;
        padding-inline-start: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        text-align: inherit;
        color: inherit;
      }
    }
  }
`;

export const NavIcons = styled('nav')`
  height: 100%;
  margin-top: 5px;

  ul {
    display: flex;
    padding-inline-start: 0px;
    margin: 0px;

    li {
      display: inline-flex;
    }
  }

  figure {
    width: 24px;
    height: 20.4px;
    @include figureInit();

    img {
      margin-top: 4px;
      width: 20px;
    }
  }

  li:not(:last-child) {
    margin-right: 20px;
  }
`;
export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
  svg {
    fill: #fff;
  }
`;
export const NavCloseOutlined = styled(CloseOutlined)`
  font-size: 3rem;
  font-weight: bold;
  svg {
    fill: #fff;
  }
`;

export const Burger = styled('div')`
  margin-left: auto;
  margin-right: 0;
  @media only screen and (max-width: 890px) {
    display: block;
  }
  display: none;
  svg {
    fill: #fff;
  }
`;
export const MenuTitle = styled('h5')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;
export const Label = styled('span')`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const MobileNavWrapper = styled('div')`
  ${NavCloseOutlined} {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  ul {
    padding-left: 40px;
    margin-bottom: 50px;
  }
  ${NavItem} {
    display: block;
    color: #fff;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavItemDisabled} {
    display: block;
    color: #a4a4a4;
    font-size: 1.8rem;
    font-weight: normal;
    margin-bottom: 30px;
  }
  ${NavIcons} {
    figure {
      width: 40px;
      margin-right: 20px;
      img {
        margin-top: 4px;
        width: 40px;
      }
    }
  }
`;
