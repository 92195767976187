import styled from 'styled-components';

export const NftInfoItemWrapper = styled('div')`
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding: 0 5rem;
  width: 30%;
  @media only screen and (max-width: 768px) {
    text-align: start;
    padding: 0 1rem;
  }
`;

export const NftInfoName = styled('p')<any>`
  font-size: 2rem;
  color: ${p => (p.color ? p.color : '#8738db')};
`;
export const NftInfoValue = styled('p')`
  font-size: 1.5rem;
  color: #fff;
`;
