import styled from 'styled-components';

export const RevealImageWrapper = styled('div')<any>`
  svg {
    background: url(${p => p.image});
    background-size: cover;
    width: 80%;
    @media only screen and (max-width: 768px) {
      width: 90%;
      margin: 2rem auto;
    }
    height: auto;
    display: block;
    margin: 10rem auto;
  }
  text {
    font-size: 10px;
    transition: font-size 0.4s ease-out;
    font-weight: 900;
    font-family: arial;
    animation: reveal 1s linear forwards;
    animation-delay: 2s;
  }
  @keyframes reveal {
    100% {
      transition: font-size 0.4s linear;
      font-size: 400px;
    }
  }
`;
