import { HOST } from '../config';
import { getConnectedWallet, WALLET_KIND } from './WalletUtil';

export async function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export async function getBlockNumber(prevBlockNum = 0): Promise<number> {
  let newBlockNumber = 0;
  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 1000);
    newBlockNumber = (
      (await (
        await fetch(`${HOST}/api/blockchain/blockNumber`, {
          signal: controller.signal
        })
      ).json()) as any
    ).blockNum;
    clearTimeout(timeoutId);
    // if (newBlockNumber <= prevBlockNum) throw {};
  } catch (e) {
    const { walletKind } = getConnectedWallet();
    try {
      if (walletKind === 'kaikas') {
        newBlockNumber = await (window as any).caver.klay.getBlockNumber();
      } else if (walletKind === 'metamask') {
        newBlockNumber = parseInt(
          await (window as any).ethereum.request({ method: 'eth_blockNumber' })
        );
      }
    } catch (e) {
      newBlockNumber = prevBlockNum + 1;
    }
  }
  return newBlockNumber;
}
