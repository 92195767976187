import React from 'react';
import {
  DescriptionContent,
  DescriptionContentWrapper,
  DescriptionImage,
  DescriptionTitle,
  DescriptionWrapper
} from './styles';

export interface DescriptionInfo {
  title: string;
  content: string;
  image?: string;
}

const Description = (props: { descContents: DescriptionInfo[] }) => {
  const { descContents } = props;
  const contents = descContents.map(info => {
    return (
      <>
        <DescriptionTitle>
          <span>{info.title}</span>
        </DescriptionTitle>
        <DescriptionContentWrapper>
          {info.image ? <DescriptionImage image={info.image} /> : null}
          <DescriptionContent>{info.content}</DescriptionContent>
        </DescriptionContentWrapper>
      </>
    );
  });

  return (
    <>
      <DescriptionWrapper>{contents}</DescriptionWrapper>
    </>
  );
};

export default Description;
