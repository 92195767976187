import React from 'react';
import { config, useSpring, animated } from '@react-spring/web';

const numberFormat = (num: number) =>
  new Intl.NumberFormat('en-us').format(num);

interface AnimateFormattedNumberProps {
  targetNumber: number;
}

const AnimateFormattedNumber = ({
  targetNumber
}: AnimateFormattedNumberProps) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: targetNumber,
    delay: 200,
    config: config.molasses
  });

  return (
    <animated.div>{number.to(n => numberFormat(Math.floor(n)))}</animated.div>
  );
};

export default AnimateFormattedNumber;
