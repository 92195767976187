import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { NumberDisplay, NumberInputButton } from './styles';

type NubmerInputProps = {
  min: number;
  max: number;
  value: number;
  onChange: (num: number) => void;
};

const NumberInput = (props: NubmerInputProps) => {
  const { value, min, max, onChange } = props;
  return (
    <>
      <NumberInputButton
        disabled={value <= min}
        onClick={() => {
          onChange(value - 1);
        }}
      >
        <MinusOutlined />
      </NumberInputButton>
      <NumberDisplay>{value}</NumberDisplay>
      <NumberInputButton
        disabled={value >= max}
        onClick={() => {
          onChange(value + 1);
        }}
      >
        <PlusOutlined />
      </NumberInputButton>
    </>
  );
};

export default NumberInput;
