import styled from 'styled-components';

export const FooterSection = styled('footer')`
  background: #0e0823;
  color: #aaa;
  text-align: center;
  font-size: 1.5rem;
  padding: 4rem 20rem;
  width: 100%;
  @media only screen and (max-width: 768px) {
    padding: 4rem 2rem;
  }
`;
