import React from 'react';
import { NftInfoItemWrapper, NftInfoName, NftInfoValue } from './styles';

interface NftInfoItemProps {
  name: string;
  value: number | string;
}

const NftInfoItem = ({ name, value }: NftInfoItemProps) => {
  return (
    <NftInfoItemWrapper>
      <NftInfoName>{name}</NftInfoName>
      <NftInfoValue>{value}</NftInfoValue>
    </NftInfoItemWrapper>
  );
};

export default NftInfoItem;
