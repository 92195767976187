import React from 'react';
import { NftInfoWrapper } from './styles';
import NftInfoItem from './NftInfoItem';

interface NftInfoProps {
  price: number | string;
  currentBlock: number;
  mintingStartAt: number;
  mintPerTx: number;
  perWallet: number | string;
}

const NftInfo = ({
  price,
  currentBlock,
  mintingStartAt,
  mintPerTx,
  perWallet
}: NftInfoProps) => {
  return (
    <>
      <NftInfoWrapper>
        <NftInfoItem name={'Current Block'} value={currentBlock} />
        <NftInfoItem name={'Minting Start At'} value={mintingStartAt} />
      </NftInfoWrapper>
      <NftInfoWrapper>
        <NftInfoItem name={'Price'} value={price} />
        <NftInfoItem name={'Mint Per Transaction'} value={mintPerTx} />
        <NftInfoItem name={'Per Wallet'} value={perWallet} />
      </NftInfoWrapper>
    </>
  );
};

export default NftInfo;
