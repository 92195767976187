import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {
  InfoWrapper,
  MintingContainer,
  MintingStatus,
  NftNameText,
  WalletButtonWrapper,
  NtfWrapper,
  MarginWrapper,
  NumberInputWrapper,
  MintWrapper,
  MintButtonWrapper,
  TitleAndButtonWrapper
} from './styles';
import { Col, Row, Statistic } from 'antd';
import ProgressBar from './components/ProgressBar';
import Description from './components/Description';
import { DescriptionContents } from './contents';
import RoundedButton from '../../components/RoundedButton';
import RevealImage from './components/RevealImage';
import { isMobile } from 'react-device-detect';
import {
  connectedWallet,
  connectKaikas,
  connectMetamask,
  mint,
  isMintable
} from '../../utils/WalletUtil';
import useInterval from 'use-interval';
import {
  getMintingBlock,
  getMintPrice,
  getProjectInfo
} from '../../utils/ProjectUtil';
import { getBlockNumber } from '../../utils/functools';
import { ProgressBarInfo } from './components/ProgressBar/ProgressBarInfo';
import NftInfo from './components/NftInfo';
import NumberInput from './components/NumberInput';

const defaultProgressBarInfo: ProgressBarInfo = {
  phase1: { name: 'Pre Sale 1', supplyQuantity: 1500 },
  phase2: { name: 'Pre Sale 2', supplyQuantity: 2500 },
  phase3: { name: 'Public Sale', supplyQuantity: 5300 },
  currentQuantity: 0
};

const Home = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [blockNumber, setBlockNumber] = useState(0);
  const [projectInfo, setProjectInfo] = useState(getProjectInfo());
  const [mintable, setMintable] = useState(false);
  const [mintNum, setMintNum] = useState(1);

  const [progressBarInfo, setProgressBarInfo] = useState(
    defaultProgressBarInfo
  );

  useEffect(() => {
    return () => {
      defaultProgressBarInfo.phase1.supplyQuantity = projectInfo.preSale1Supply;
      defaultProgressBarInfo.phase2.supplyQuantity = projectInfo.preSale2Supply;
      defaultProgressBarInfo.phase3.supplyQuantity =
        projectInfo.publicSaleSupply;
      defaultProgressBarInfo.currentQuantity = projectInfo.tokenIndex;

      setProgressBarInfo(defaultProgressBarInfo);
    };
  }, [projectInfo]);

  useInterval(() => {
    (async () => {
      setBlockNumber(await getBlockNumber(blockNumber));
    })();
  }, 1000);

  useInterval(() => {
    setProjectInfo({ ...getProjectInfo() });
  }, 100);

  return (
    <>
      <Header />
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.6)',
          zIndex: '100'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '400px',
            background: 'rgba(0,0,0,0.8)',
            color: '#fff',
            fontSize: '3rem',
            margin: 'auto',
            marginTop: '20%',
            padding: '2rem'
          }}
        >
          This is klanium team. We apologize to those who were excited about the
          first Klanium project. We apologize for not being able to closely
          check the lolclub project during our collaboration. We will ask lol
          club for a full refund so that klanians will not be harmed. We are
          sorry to disappoint you again.
        </div>
      </div>
      <MintingContainer>
        <Row justify={'center'} gutter={[30, 30]}>
          <Col xs={24} xl={8}>
            <NtfWrapper>
              <RevealImage image={'lol_club_nft.png'} />
            </NtfWrapper>
          </Col>
          <Col xs={20} xl={16}>
            <InfoWrapper>
              <MarginWrapper>
                <TitleAndButtonWrapper>
                  <NftNameText>LOL CLUB</NftNameText>
                  <MintingStatus>Available</MintingStatus>
                  <WalletButtonWrapper>
                    {walletAddress ? (
                      <RoundedButton
                        name={walletAddress.substring(0, 10)}
                        onclick={() => setWalletAddress('')}
                      />
                    ) : (
                      <>
                        {isMobile ? null : (
                          <RoundedButton
                            name={'Connect Kaikas'}
                            image={'kaikas.png'}
                            margin={'0 2rem 0 0'}
                            onclick={async () => {
                              const walletAddress = await connectKaikas();
                              setWalletAddress(walletAddress);
                              const mintable = await isMintable(
                                walletAddress,
                                'kaikas'
                              );
                              setMintable(mintable);
                            }}
                          />
                        )}
                        <RoundedButton
                          name={'Connect Metamask'}
                          image={'metamask.svg'}
                          onclick={async () => {
                            const walletAddress = await connectMetamask();
                            setWalletAddress(walletAddress);
                            const mintable = await isMintable(
                              walletAddress,
                              'metamask'
                            );
                            setMintable(mintable);
                          }}
                        />
                      </>
                    )}
                  </WalletButtonWrapper>
                </TitleAndButtonWrapper>
              </MarginWrapper>
              <MarginWrapper>
                <ProgressBar {...progressBarInfo} />
              </MarginWrapper>
              <MintWrapper>
                <NumberInputWrapper>
                  <NumberInput
                    min={1}
                    max={2}
                    value={mintNum}
                    onChange={num => setMintNum(num)}
                  />
                </NumberInputWrapper>
                <MintButtonWrapper>
                  {/* <RoundedButton
                    name={'Mint Now'}
                    image={'logo_klanium.png'}
                    isDisabled={!walletAddress || !mintable}
                    onclick={async () => {
                      mint(mintNum);
                    }}
                  /> */}
                </MintButtonWrapper>
              </MintWrapper>
              <MarginWrapper>
                <NftInfo
                  price={getMintPrice(projectInfo)}
                  currentBlock={blockNumber}
                  mintingStartAt={getMintingBlock(projectInfo)}
                  mintPerTx={projectInfo.maxMintAmount}
                  perWallet={'Unlimited'}
                />
              </MarginWrapper>

              <Description descContents={DescriptionContents} />
            </InfoWrapper>
          </Col>
        </Row>
      </MintingContainer>
      <Footer />
    </>
  );
};

export default Home;
