import React from 'react';
import { ButtonWrapper, DisabledButton } from './styles';

interface RoundedButtonProps {
  name: string;
  image?: string;
  isDisabled?: boolean;
  onclick?: () => void;
  margin?: string;
}

const RoundedButton = ({
  name,
  image,
  isDisabled = false,
  onclick,
  margin
}: RoundedButtonProps) => {
  const button = isDisabled ? (
    <DisabledButton image={image} margin={margin}>
      {name}
    </DisabledButton>
  ) : (
    <ButtonWrapper image={image} onClick={onclick} margin={margin}>
      {name}
    </ButtonWrapper>
  );

  return <>{button}</>;
};

export default RoundedButton;
